import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Layout from "../components/layout";
import ScrollSection from "../components/scroll-section/scroll-section";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import ArticleList from "../components/article-list/article-list";

class ContientPageTemplate extends React.Component {
  render() {
    const posts = get(this.props, "data.allContentfulPost.edges");

    const category = get(this.props, "data.contentfulCategory");
    const continents = get(this, "props.data.allContentfulContinent.edges");
    const { title } = category;

    return (
      <Layout
        classNames={{ container: "bg-red-lightest" }}
        continents={continents}
        location={this.props.location}
        status={this.props.transitionStatus}
      >
        <Seo
          type="page"
          content={{
            title: `${title} tagged travel stories`,
            seoDescription: `Travel stories by Hodophile Travel tagged under the term ${title}.`,
          }}
        />
        <ScrollSection>
          <Hero
            title={title.toUpperCase()}
            type="default"
            pretitle={{
              title: "Tagged travel stories",
            }}
            subtitle={`Travel stories tagged ${title}`}
          />
        </ScrollSection>
        <ScrollSection>
          <ArticleList posts={posts} />
        </ScrollSection>
      </Layout>
    );
  }
}

export default ContientPageTemplate;

export const pageQuery = graphql`
  query TaggedQuery($id: String!) {
    contentfulCategory(id: { eq: $id }) {
      id
      title
      slug
    }
    allContentfulContinent(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          title
          slug
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
        }
      }
    }
    allContentfulPost(
      filter: { categories: { elemMatch: { id: { eq: $id } } } }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate
          minuteRead
          heroImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 424
              height: 212
            )
          }
          excerpt {
            excerpt
            childMarkdownRemark {
              html
            }
          }
          author {
            displayTitle
            firstname
            surname
            location
            slug
          }
          categories {
            id
          }
          country {
            slug
            title
            continent {
              title
              slug
            }
          }
        }
      }
    }
  }
`;
